import { colors, screens, breakpoints, fonts, images } from './styles';

export const theme = {
  colors,
  font: {
    family: fonts,
    size: {
      normal: 'medium',
      xxs: 'xx-small',
      xs: 'x-small',
      sm: 'small',
      lg: 'large',
      xl: 'x-large',
      xxl: 'xx-large',
      sm2: 'smaller',
      lg2: 'larger',
    },
  },
  breakpoints,
  screens,
  images,
  width: {
    none: 'none',
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      xl2: '42rem',
      xl3: '48rem',
      xl4: '56rem',
      xl5: '64rem',
      xl6: '72rem',
      xl7: '80rem',
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      prose: '65ch',
  },
  height: {
    none: 'none',
      full: '100%',
      screen: '100vh',
      svh: '100svh',
      lvh: '100lvh',
      dvh: '100dvh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
  }
};
