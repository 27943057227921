import React from "react";
import { theme } from "../styles/theme";
import { Background, Heading3, Text } from "../styles/elements";
import Box from "../styles/Box";
import styled from "styled-components";
import SoundCloudPlayer from "./SoundCloudPlayer";
import VimeoPlayer from "./VimeoPlayer";

const Overlay = styled(Box)`
  background-color: #00000090;
`

Overlay.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
};

export default function Services() {
  return (
    <>
      <Background width={[1,1,1,1/2]} backgroundImage={`linear-gradient(200deg, ${theme.colors.dark} 0%, ${theme.colors.lunar} 30%, ${theme.colors.dark} 90%);`}>
        <Overlay height={'100%'} padding={'2.5rem'}>
          <Heading3 noShadow dark textAlign={'center'}>Content Creation</Heading3>
          <Box height={'10rem'} backgroundSize={'cover'} marginY={'1em'} backgroundPosition={'center 10%'} backgroundImage={`url(${theme.images.podcaster})`} />
          <Text noShadow dark textAlign={'center'} fontWeight={600}>Starting @ $200/episode</Text>
          <Text noShadow dark textAlign={"left"}>Have your video or podcast cleaned, edited, and polished for streaming and distribution. We are here to help you tailor your intros, outros, and other content to your personality making your content more unique to you or your company. Audio and visual elements are as important as your content. Without them your message will have a hard time getting across to your audience which affects the monetization oportunities for your channel. Let us help you tighten up your production in order to provide quality content that will capture your viewer's attention and help your message stick.</Text>
        </Overlay>
      </Background>
      <Background width={[1,1,1,1/2]} backgroundImage={`linear-gradient(130deg, ${theme.colors.dark} 0%, ${theme.colors.gold} 30%, ${theme.colors.dark} 90%);`}>
        <Overlay height={'100%'} padding={'2.5rem'}>
          <Heading3 noShadow dark textAlign={'center'}>Post-Production</Heading3>
          <Box height={'10rem'} backgroundSize={'cover'} marginY={'1em'} backgroundPosition={'center 10%'} backgroundImage={`url(${theme.images.video_production})`} />
          <Text noShadow dark textAlign={'center'} fontWeight={600}>Starting @ $250/video</Text>
          <Text noShadow dark textAlign={"left"}>Providing audio and video editing that includes content enhancement, source audio cleanup and repairs, audio-visual synchronization, sound design, and music production. When working on multiple aspects of your project we will provide a project manager who will be your single point of contact through the production process keeping you up to date on all areas and managing all teams on your behalf.</Text>
          <SoundCloudPlayer url={"https%3A//api.soundcloud.com/tracks/1836138870"} />
        </Overlay>
      </Background>
      <Background width={[1,1,1,1/2]} backgroundImage={`linear-gradient(200deg, ${theme.colors.dark} 0%, ${theme.colors.lunar} 30%, ${theme.colors.dark} 90%);`}>
        <Overlay height={'100%'} padding={'2.5rem'}>
          <Heading3 noShadow dark textAlign={'center'}>Music Production</Heading3>
          <Box height={'10rem'} backgroundSize={'cover'} marginY={'1em'} backgroundPosition={'center 40%'} backgroundImage={`url(${theme.images.music_production})`} />
          <Text noShadow dark textAlign={'center'} fontWeight={600}>Starting @ $50/cue</Text>
          <Text noShadow dark textAlign={"left"}>We offer music production for various uses such as film score, interactive media, music loops, background cues, and music beds. We keep a growing curated library of music including music from some of our clients as well as our own. You can also feel free to contract us to have pieces made for your project exclusively.</Text>
          <SoundCloudPlayer playlist url={"https%3A//api.soundcloud.com/playlists/1831981995"} />
        </Overlay>
      </Background>
      <Background width={[1,1,1,1/2]} backgroundImage={`linear-gradient(130deg, ${theme.colors.dark} 0%, ${theme.colors.gold} 30%, ${theme.colors.dark} 90%);`}>
        <Overlay height={'100%'} padding={'2.5rem'}>
          <Heading3 noShadow dark textAlign={'center'}>Mixing & Mastering</Heading3>
          <Box height={'10rem'} marginY={'1em'} backgroundPosition={'center 10%'} backgroundImage={`url(${theme.images.singer_songwriter})`} backgroundSize={'cover'} />
          <Text noShadow dark textAlign={'center'} fontWeight={600}>Starting @ $200/piece</Text>
          <Text noShadow dark textAlign={"left"}>Once we balance each instrument's volume and optimize their dynamic range we focus on balancing the frequency spectrum and the sound stage into a cohesive final mix. Afterwhich we optimize the overall volume and loudness of the entire piece. The master audio file is delivered in raw format as well as versions ready for distribution and streaming, all fully tagged with the metadata that you provide.</Text>
          <SoundCloudPlayer playlist url={"https%3A//api.soundcloud.com/playlists/1831981608"} />
        </Overlay>
      </Background>
      <Background width={[1,1,1,1]} backgroundImage={`linear-gradient(180deg, ${theme.colors.dark} 0%, ${theme.colors.lunar} 50%, ${theme.colors.dark} 100%);`}>
        <Overlay height={'100%'} padding={['2.5rem', '2.5rem', '2.5rem', '5rem 10rem']}>
          <Heading3 noShadow dark textAlign={'center'}>Sound Design & Editing</Heading3>
          <Box height={'10rem'} backgroundSize={'cover'} marginY={'1em'} backgroundPosition={'center 40%'} backgroundImage={`url(${theme.images.sound_designer})`} />
          <Text noShadow dark textAlign={'center'} fontWeight={600}>Starting @ $50/design</Text>
          <Text noShadow dark textAlign={"left"}>Soundscaping and sound futzing are also offered to help you bring your scenes or games to life. For game developers, we can help with casting and recording the voices needed for your script. We'll sync your audio to the cut-scenes and prepare the in-game audio for implementation by one of your developers.</Text>
          <VimeoPlayer url={'https://player.vimeo.com/video/955027689?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} title={'Aeon Flux Example'} />
        </Overlay>
      </Background>
    </>
  );
}
