import { css } from "styled-components";

export const images = {
  logo_square: "https://alkenox-media.s3.amazonaws.com/images/alkenox_logo-sqr.svg",
  logo_rectangle: "https://alkenox-media.s3.amazonaws.com/images/alkenox_logo-fancy-rect.svg",
  music_studio: "https://alkenox-media.s3.amazonaws.com/images/Music_Studio_1.jpg",
  video_production: "https://alkenox-media.s3.amazonaws.com/images/Video_Editing_3.jpg",
  singer_songwriter: "https://alkenox-media.s3.amazonaws.com/images/Singer_Songwriter_2.jpg",
  music_producer: "https://alkenox-media.s3.amazonaws.com/images/Music_Producer_4.jpg",
  podcaster: "https://alkenox-media.s3.amazonaws.com/images/Podcaster_1.jpg",
  content_creator: "https://alkenox-media.s3.amazonaws.com/images/Content_Creator_1.jpg",
  sound_designer: "https://alkenox-media.s3.amazonaws.com/images/Sound_Designer_2.jpg",
  voice_actor: "https://alkenox-media.s3.amazonaws.com/images/Voice_Actress_1.jpg",
  audio_repair: "https://alkenox-media.s3.amazonaws.com/images/Audio_Repair_4.jpg",
  mastering: "https://alkenox-media.s3.amazonaws.com/images/Mastering_Desk.jpg",
  music_production: "https://alkenox-media.s3.amazonaws.com/images/Music_Production_1.jpg",
  consultation: "https://alkenox-media.s3.amazonaws.com/images/Consultation.jpg",
  collab: "https://alkenox-media.s3.amazonaws.com/images/Creative_Collaborators_2.jpg",
  box1: "https://alkenox-media.s3.amazonaws.com/images/Drop+Box+1.jpeg",
  box2: "https://alkenox-media.s3.amazonaws.com/images/Drop+Box+3.jpeg",
  box3: "https://alkenox-media.s3.amazonaws.com/images/Drop+Box+4.jpeg",
};

export const breakpoints = ["400", "600px", "800px", "1200px"];

export const screens = {
  sm: breakpoints[0],
  md: breakpoints[1],
  lg: breakpoints[2],
  xl: breakpoints[3],
  print: { raw: "print" },
};

export const fonts = {
  caption: "'Archivo Black', sans-serif",
  black: "'Archivo Black', sans-serif",
  title: "'Playfair Display SC', serif",
  subtitle: "'Playfair Display', serif",
  body: "'Poppins', sans-serif"
}
export const colors = {
  transparent: "transparent",
  current: "currentColor",
  darkDim: "#161616e0",
  lightDim: "#ffffffd0",
  black: "#080808",
  dark: "#161616",
  gray: "#818181",
  white: "#ffffff",
  gold: "#ffd700",
  darkGold: "#bda000",
  silver: "#cfcfcf",
  lunar: "#B0BEC5",
  red: "#7C0D0E",
  navy: "#001F3F",
  vibrant: "#7561F0",
  error: "#FF4444",
  success: "#44ff44",
  lightLink: "#fff1a0",
  darkLink: "#bda000",
};

export const text = {
  wrap: (direction: 'left'|'right') => css`
      float: ${direction};
      margin-right: 1em;
    `,
  indent: css`
    text-indent: 1rem;
  `,
  color: (darkMode: boolean, light: string = colors.white, dark: string = colors.dark) => css`
    color: ${darkMode ? light : dark};
  `,
  link: (darkMode: boolean) => css`
    color: ${darkMode ? colors.white : colors.dark};
    font-decoration: underline;
    &:hover {
      color: ${colors.gold};
      cursor: pointer;
    }
  `,
  shadow: (darkMode: boolean, light: string = colors.white, dark: string = colors.black) => css`
    text-shadow: 0 0 0.25rem ${darkMode ? dark : light};
  `,
  sizer: (defaultSize: number, baseSize: number = 1) => css`
    font-size: ${baseSize * defaultSize}rem;
    @media (max-width: ${props => props.theme.screens.lg}) {font-size: ${baseSize * defaultSize * 0.95}rem;}
    @media (max-width: ${props => props.theme.screens.md}) {font-size: ${baseSize * defaultSize * 0.9}rem;}
  `
  }

export const flex = {
  container: css`
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: space-between; /* Distribute items evenly along the main axis */
  `,
  item: (
    spacing: string = '1em',
  ) => css`
    flex: 1; /* Allow item to grow and shrink based on available space */
    margin: ${spacing}; /* Add margin between items */
`}

export const bg = {
  image: (src: string) => css`
    background-image: url(${src});
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    background-clip: border-box;
    background-origin: border-box;
    background-repeat: no-repeat;
  `,
  overlay: (
    darkMode: boolean, 
    dark: string = colors.darkDim, 
    light: string = colors.lightDim
  ) => css`
    background-color: ${props => darkMode ? dark : light};
  `,
  gradient: {
    linear: (
      colors: string[], 
      direction: string = 'to bottom', 
      repeat: boolean = false
    ) => css`
      ${repeat && 'repeating-'}linear-gradient(${direction}, ${colors.join(', ')})
    `,
    radial: (
      colors: string[], 
      repeat: boolean = false
    ) => css`
      ${repeat && 'repeating-'}radial-gradient(${colors.join(', ')})
    `,
    conic: (
      colors: string[], 
      repeat: boolean = false
    ) => css`
      ${repeat && 'repeating-'}conic-gradient(${colors.join(', ')})
    `,
  }
}

export const space = {
  px: (src: string) => css`
    padding-left: ${src};
    padding-right: ${src};
  `,
  py: (src: string) => css`
    padding-top: ${src};
    padding-bottom: ${src};
  `,
  mx: (src: string) => css`
    margin-left: ${src};
    margin-right: ${src};
  `,
  my: (src: string) => css`
    margin-top: ${src};
    margin-bottom: ${src};
  `,
  gap: (src: string) => css`
    gap-left: ${src};
    gap-right: ${src};
    gap-top: ${src};
    gap-bottom: ${src};
  `,
}

export const content = {
  centerXY: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
  centerX: css`
    display: flex;
    flex-direction: column;
  `,
}

export const responsive = {
  grid: (
    sm: string = "12rem",
    md: string = "12rem",
    lg: string = "12rem",
    xl: string = "12rem",
    def: string = "12rem"
  ) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${def}, 1fr));
    @media (${props => props.theme.screens.xl}) {
        grid-template-columns: repeat(auto-fit, minmax(${xl}, 1fr));
      }
    @media (${props => props.theme.screens.lg}) {
        grid-template-columns: repeat(auto-fit, minmax(${lg}, 1fr));
      }
    @media (${props => props.theme.screens.md}) {
        grid-template-columns: repeat(auto-fit, minmax(${md}, 1fr));
      }
    @media (${props => props.theme.screens.sm}) {
        grid-template-columns: repeat(auto-fit, minmax(${sm}, 1fr));
      }
    @media (${props => props => props.theme.screens.xs}) {
      grid-template-columns: 1fr;
    }
  `,
  style: (
    def?: string,
    xs?: string,
    sm?: string,
    md?: string,
    lg?: string,
    xl?: string,
    print?: string
  ) => css`
    ${def}
    ${props => 
      xl &&
      `@media (${props.theme.screens.xl}) {
        ${xl}
      }`
    } 
    ${props => 
      lg &&
      `@media (${props.theme.screens.lg}) {
        ${lg}
      }`
    } 
    ${props => 
      md &&
      `@media (${props.theme.screens.md}) {
      ${md}
    }`
    } 
    ${props => 
      sm &&
      `@media (${props.theme.screens.sm}) {
      ${sm}
    `
    } }
    ${props => 
      xs &&
      `@media (${props.theme.screens.xs}) {
      ${xs}
    }`
    }
    ${props => print && `@media (${props.theme.screens.print}) {
      ${print}
    }`
  }`
}
