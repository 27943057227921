import React from "react";
import {
  Container,
  Inner,
  Heading1,
  Heading2,
  Page,
  Section,
  Text,
  List
} from "../styles/elements";

interface IProps {}

const PrivacyPolicy: React.FC<IProps> = () => {
  const email = "info@alkenox.com";

  return (
    <Page>
      <Section dark>
        <Container padding={["5rem 2.5rem", "5rem 10rem"]}>
          <Inner marginY={"1rem"}>
            <Heading1 dark textAlign={'center'}>Privacy Policy</Heading1>
            <Text dark>
              Alkenox, a branded service provided by LeeVan Enterprises, LLC ("we,"
              "us," or "our") is committed to protecting the privacy and
              security of your personal information. This Privacy Policy
              describes how we collect, use, and disclose the personal
              information of our clients and visitors to our website.
            </Text>
          </Inner>

          <Inner marginY={"1rem"}>
            <Heading2 dark>
              <span style={{ textAlign: "left" }}>
                1. Information We Collect
              </span>
            </Heading2>
            <Text dark>
              We may collect the following types of personal information from
              you:
            </Text>
            <List dark>
              <li>
                Contact Information: Name, email address, phone number, and
                mailing address.
              </li>
              <li>
                Project Information: Details about your projects, including
                audio or video files, project requirements, and preferences.
              </li>
              <li>
                Payment Information: Billing address, credit card number, and
                other payment details for processing transactions.
              </li>
              <li>
                Communication Information: Correspondence and communications
                with us, including emails, messages, and feedback.
              </li>
            </List>
          </Inner>

          <Inner marginY={"1rem"}>
            <Heading2 dark>
              <span style={{ textAlign: "left" }}>
                2. How We Use Your Information
              </span>
            </Heading2>
            <Text dark>
              We may use your personal information for the following purposes:
            </Text>
            <List dark>
              <li>
                Providing Services: Fulfilling your requests for audio
                post-production, mixing, mastering, and other services.
              </li>
              <li>
                Communication: Responding to your inquiries, providing updates
                on project progress, and communicating with you about our
                services.
              </li>
              <li>
                Billing and Payment: Processing payments, invoicing, and
                managing billing-related matters.
              </li>
              <li>
                Improving Services: Analyzing usage data, feedback, and client
                preferences to enhance our services and user experience.
              </li>
              <li>
                Legal Compliance: Complying with legal obligations, resolving
                disputes, and enforcing our rights and agreements.
              </li>
            </List>
          </Inner>

          <Inner marginY={"1rem"}>
            <Heading2 dark>
              <span style={{ textAlign: "left" }}>
                3. Information Sharing and Disclosure
              </span>
            </Heading2>
            <Text dark>
              We may share your personal information with third parties in the
              following circumstances:
            </Text>
            <List dark>
              <li>
                Service Providers: Sharing information with third-party service
                providers who assist us in providing services, processing
                payments, or performing other business functions.
              </li>
              <li>
                Legal Requirements: Disclosing information in response to legal
                process, court orders, or government requests, or to protect our
                rights, property, or safety.
              </li>
              <li>
                Business Transfers: Sharing information in connection with a
                merger, acquisition, restructuring, or sale of assets, where
                personal information may be transferred as part of the
                transaction.
              </li>
            </List>
          </Inner>

          <Inner marginY={"1rem"}>
            <Heading2 dark>
              <span style={{ textAlign: "left" }}>4. Data Security</span>
            </Heading2>
            <Text dark>
              We take reasonable measures to protect the security and
              confidentiality of your personal information. However, please note
              that no method of transmission over the internet or electronic
              storage is completely secure, and we cannot guarantee absolute
              security.
            </Text>
          </Inner>

          <Inner marginY={"1rem"}>
            <Heading2 dark>
              <span style={{ textAlign: "left" }}>
                5. Your Choices and Rights
              </span>
            </Heading2>
            <Text dark>
              You have certain rights and choices regarding your personal
              information, including the right to access, update, or delete your
              information. Please contact us at {email} to exercise your rights
              or make inquiries about your personal information.
            </Text>
          </Inner>

          <Inner marginY={"1rem"}>
            <Heading2 dark>
              <span style={{ textAlign: "left" }}>6. Children's Privacy</span>
            </Heading2>
            <Text dark>
              Our services are not directed to children under the age of 13, and
              we do not knowingly collect personal information from children. If
              you believe that a child has provided personal information to us,
              please contact us, and we will take appropriate steps to delete
              such information.
            </Text>
          </Inner>

          <Inner marginY={"1rem"}>
            <Heading2 dark>
              <span style={{ textAlign: "left" }}>
                7. Changes to this Privacy Policy
              </span>
            </Heading2>
            <Text dark>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We will notify you
              of any material changes by posting the updated policy on our
              website or by other means of communication.
            </Text>
          </Inner>

          <Inner marginY={"1rem"}>
            <Heading2 dark>
              <span style={{ textAlign: "left" }}>8. Contact Us</span>
            </Heading2>
            <Text dark>
              If you have any questions, concerns, or comments about this
              Privacy Policy or our privacy practices, please contact us at
              {email}.
            </Text>
          </Inner>
        </Container>
      </Section>
    </Page>
  );
};

export default PrivacyPolicy;
