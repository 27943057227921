import styled from 'styled-components';
import { space, layout, color, typography, flexbox, grid, background, border, position, SpaceProps, LayoutProps, ColorProps, TypographyProps, FlexboxProps, GridProps, BackgroundProps, BorderProps, PositionProps } from 'styled-system';

type BoxProps = SpaceProps & LayoutProps & ColorProps & TypographyProps & FlexboxProps & GridProps & BackgroundProps & BorderProps & PositionProps;

export const UtilityBox = styled.div<BoxProps>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
`;

export const Btn = styled.button<BoxProps>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${background}
  ${border}
  ${position}
`;

export const Image = styled.img<BoxProps>`
  ${space}
  ${layout}
  ${border}
  ${position}
  ${flexbox}
  ${grid}
`;

export const Embed = styled.iframe<BoxProps>`
  ${space}
  ${layout}
  ${border}
  ${position}
  ${flexbox}
  ${grid}
  ${color}
  ${typography}
`;

const Box = styled.div<BoxProps>`
  ${space}
  ${layout}
  ${border}
  ${position}
  ${flexbox}
  ${grid}
  ${background}
`;

export default Box;
