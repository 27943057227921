import React from 'react';
import { Helmet } from "react-helmet";

const Hat: React.FC = () => {
  return (
    <Helmet>
        {/**
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
         */}
        <meta 
            name="description" 
            content="
                Alkenox™️ offers media and entertainment services that empower creators across diverse fields, transforming their raw concepts into immersive and captivating experiences.
            "
        />
        <meta name="keywords" content="
            Mixing and mastering,
            Audio post-production,
            Music mixing services,
            Music mastering services,
            Film post-production,
            Content creation services,
            Audio engineering,
            Professional mixing,
            Professional mastering,
            Sound design services,
            Podcast editing services,
            Audio enhancement,
            Soundtrack production,
            Voiceover mixing,
            Foley sound services,
            ADR (Automated Dialogue Replacement),
            Independent film sound editing,
            Music production services,
            Audio post for video,
            Custom sound design,
            Affordable mixing and mastering for indie artists,
            Best audio post-production services for filmmakers,
            How to get professional mixing and mastering online,
            Custom soundtrack production for independent films,
            Top podcast editing services for content creators,
            High-quality sound design for video games,
            Professional voiceover mixing for YouTube videos,
            Comprehensive audio post-production for documentaries,
            Where to find reliable mixing and mastering services,
            Benefits of professional audio enhancement for podcasts,
            Alkenox mixing services,
            Alkenox mastering services,
            Alkenox audio post-production,
            Alkenox music production,
            Alkenox content creation,
            Alkenox sound design,
            Alkenox podcast editing,
            Alkenox film audio services
            "
        />
        <title>Alkenox&trade;</title>
      </Helmet>
  );
};

export default Hat;