import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { typography, space, layout, SpaceProps, LayoutProps, TypographyProps } from 'styled-system';

type PropsType = SpaceProps & LayoutProps & TypographyProps;

// Define styled components for different text elements
export const PageHeading = styled.h1<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

export const SectionHeading = styled.h2<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

export const SubHeading = styled.h3<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

export const CaptionHeading = styled.h4<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

export const Body = styled.p<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

export const NavLink = styled(Link)<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

export const AnchorLink = styled.a<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

export const Base = styled.p<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

export const Caption = styled.caption<Partial<PropsType>>`
  ${layout}
  ${space}
  ${typography}
`;

// Text component that renders different text elements based on props
const Text = (children: JSX.Element, {...rest}, type?: string) => {
  switch (type) {
    case 'page_header':
      return <PageHeading {...rest}>{children}</PageHeading>;
    case 'section_header':
      return <SectionHeading {...rest}>{children}</SectionHeading>;
    case 'sub_header':
      return <SubHeading {...rest}>{children}</SubHeading>;
    case 'caption_header':
      return <CaptionHeading {...rest}>{children}</CaptionHeading>;
    case 'caption':
      return <Caption {...rest}>{children}</Caption>;
    case 'body':
      return <Body {...rest}>{children}</Body>;
    default:
      return <Base {...rest}>{children}</Base>;
  }
};

export default Text;
