import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/home';
import Layout from './Layout';
import TermsAndConditions from './termsAndConditions';
import PrivacyPolicy from './privacyPolicy';

const App: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Layout>
  );
};

export default App;
