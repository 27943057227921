import React, { useState } from "react";
import styled from "styled-components";
import { ChevLeft, ChevRight } from "../styles/icons";
import Box, { Btn } from "../styles/Box";
import { Section, Overlay, Heading2, Text } from "../styles/elements";
import { theme } from "../styles/theme";

// Define prop types for the Slider component
type SliderProps = {
  slides: React.ReactNode[];
};

// Styled components for the Slider
const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const SlideContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

const SlideItem = styled.div`
  flex: 0 0 100%;
  display: flex;
  align-items: center;
`;

const SlideContentWrapper = styled.div`
  width: 100%;
`;

const SlideButton = styled(Btn)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1;
  color: ${(props) => props.theme.colors.white};
  &:hover {
    color: ${(props) => props.theme.colors.gold};
  }
`;

interface ISlide {
  hero?: boolean;
  image: string;
  heading: string;
  description?: string;
  content?: React.ReactNode;
}

export const Slide: React.FC<ISlide> = (props: ISlide) => (
  <Section image={props.image} width={1} minHeight={"75vh"}>
    {props.hero && (
      <Overlay
        m={"auto"}
        width={[1, 1 / 2, 1 / 1.5, 1 / 1.5]}
        minHeight={["75vh", "auto", "auto", "auto"]}
        marginX={"auto"}
        padding={["1rem", "2rem", "2rem", "2rem"]}
        borderRadius={[0, "1rem", "1rem", "1rem"]}
      >
        <Heading2 textAlign={"center"}>{props.heading}</Heading2>
        {props.description && (
          <Text
            textAlign={"justify"}
            paddingX={["4rem", "1rem", "1rem", "1rem"]}
          >
            {props.description}
          </Text>
        )}
        {props.content}
      </Overlay>
    )}
  </Section>
);

// Slider component
const Slider: React.FC<SliderProps> = ({ slides }: SliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <SliderWrapper>
      <SlideContainer
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <SlideItem key={index}>
            <SlideContentWrapper>{slide}</SlideContentWrapper>
          </SlideItem>
        ))}
      </SlideContainer>
      <SlideButton onClick={prevSlide} left={"0.125rem"} width={[1/4,1/4,1/10,1/15]}>
        <ChevLeft color={ theme.colors.white } colorOnHover={ theme.colors.gold } />
      </SlideButton>
      <SlideButton onClick={nextSlide} right={"0.125rem"} width={[1/4,1/4,1/10,1/15]}>
        <ChevRight color={ theme.colors.white } colorOnHover={ theme.colors.gold } />
      </SlideButton>
    </SliderWrapper>
  );
};

export default Slider;
