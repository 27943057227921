import React from "react";
import { Container, Heading1, Heading2, Inner, Page, Section, Text } from "../styles/elements";

interface IProps {}

const TermsAndConditions: React.FC<IProps> = () => {
  const company = "Alkenox";
  const arbitrationInstitution = "";

  return (
    <Page><Section dark>
    <Container padding={["5rem 2.5rem", "5rem 10rem"]}>
      <Heading1 dark>Terms and Conditions</Heading1>

      <Inner marginY={"1rem"}>
        <Heading2 dark>1. Introduction</Heading2>
        <Text dark>
          1.1 These Terms and Conditions govern the use of services provided by{" "}
          {company}, a branded service from LeeVan Enterprises, a limited
          liability company registered in United States of America
          {/** , with registered office at [Your Address] */}.
        </Text>
      </Inner>

      <Inner marginY={"1rem"}>
        <Heading2 dark>2. Scope of Services</Heading2>
        <Text dark>
          2.1 {company} offers post-production, music mixing, mastering, and
          related services as described on the website and agreed upon with the
          client.
        </Text>

        <Text dark>
          2.2 Clients agree to provide necessary materials, files, and
          information required for the provision of services in a timely manner.
        </Text>
      </Inner>

      <Inner marginY={"1rem"}>
        <Heading2 dark>3. Pricing and Payment</Heading2>
        <Text dark>
          3.1 Pricing for services is determined based on the scope, complexity,
          and duration of the project. Quotes provided by {company} are valid
          for a period of 30 days from the date of issuance.
        </Text>

        <Text dark>
          3.2 Payment terms are outlined in the invoice provided to the client.
          Payment is due 10 business days from the date of invoice issuance
          unless otherwise specified.
        </Text>

        <Text dark>
          3.3 {company} reserves the right to suspend services or withhold
          deliverables until payment is received in full.
        </Text>
      </Inner>

      <Inner marginY={"1rem"}>
        <Heading2 dark>4. Intellectual Property</Heading2>
        <Text dark>
          4.1 All intellectual property rights, including copyrights,
          trademarks, and other proprietary rights, in deliverables produced by{" "}
          {company} remain the property of {company} unless otherwise agreed
          upon in writing.
        </Text>

        <Text dark>
          4.2 Clients retain ownership of their original content and materials
          provided to {company} for the purpose of service provision.
        </Text>
      </Inner>

      <Inner marginY={"1rem"}>
        <Heading2 dark>5. Confidentiality</Heading2>
        <Text dark>
          5.1 {company} agrees to keep confidential all proprietary and
          sensitive information shared by the client, including but not limited
          to project details, files, and communications.
        </Text>

        <Text dark>
          5.2 Clients agree to keep confidential any proprietary or sensitive
          information shared by {company} in the course of service provision.
        </Text>
      </Inner>

      <Inner marginY={"1rem"}>
        <Heading2 dark>6. Limitation of Liability</Heading2>
        <Text dark>
          6.1 {company} shall not be liable for any indirect, incidental,
          special, or consequential damages arising out of or in connection with
          the provision of services, including but not limited to loss of
          profits, data, or business opportunities.
        </Text>
      </Inner>

      <Inner marginY={"1rem"}>
        <Heading2 dark>7. Termination</Heading2>
        <Text dark>
          7.1 Either party may terminate the agreement upon 10 business days
          written notice if the other party breaches any material provision of
          these Terms and Conditions.
        </Text>
      </Inner>

      <Inner marginY={"1rem"}>
        <Heading2 dark>8. Governing Law and Dispute Resolution</Heading2>
        <Text dark>
          8.1 These Terms and Conditions shall be governed by and construed in
          accordance with the laws of United States of America.
        </Text>

        <Text dark>
          8.2 Any disputes arising out of or in connection with these Terms and
          Conditions shall be resolved through arbitration in accordance with
          the rules of {arbitrationInstitution}.
        </Text>
      </Inner>

      <Inner marginY={"1rem"}>
        <Heading2 dark>9. Miscellaneous</Heading2>
        <Text dark>
          9.1 These Terms and Conditions constitute the entire agreement between{" "}
          {company} and the client and supersede all prior agreements and
          understandings, whether oral or written.
        </Text>

        <Text dark>
          9.2 {company} reserves the right to update or modify these Terms and
          Conditions at any time. Clients will be notified of any changes via
          email or through the website.
        </Text>
      </Inner>
      </Container>
    </Section></Page>
  );
};

export default TermsAndConditions;
