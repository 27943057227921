import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { api } from "../utils/config";

// Define the type for the response data from the backend
interface ApiResponse<T> {
  success: boolean;
  data: T;
  message?: string;
}

// Define the custom hook for making HTTP requests
function useAxios<T>(
  apiEndpoint: string = api.endpoint,
  apiKey: string = api.key as string,
  apiUrl: string = api.base_url,
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const url = `${apiUrl}${apiEndpoint}` //?api_key=${apiKey}

  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': apiKey
  };

  const timeout = 30000;

  // Function to send a request to the backend
  const sendRequest = async (env: AxiosRequestConfig): Promise<T | null> => {
    setLoading(true);
    setError(null);

    try {
      const response: AxiosResponse<ApiResponse<T>> = await axios(env);
      setLoading(false);

      if (response.data.success) {
        return response.data.data;
      } else {
        setError(response.data.message || "An error occurred");
        console.log(error);
        return null;
      }
    } catch (error) {
      setLoading(false);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError<ApiResponse<T>> = error;
        setError(axiosError.response?.data.message || "An error occurred");
      } else {
        setError("An error occurred");
      }
      console.log(error);
      return null;
    }
  };

  // Function to send a GET request
  const fetchData = async (): Promise<T | null> => {
    return await sendRequest({
      method: "GET",
      timeout,
      url,
      headers,
    });
  };

  // Function to send a POST request
  const createData = async (data: any): Promise<T | null> => {
    return await sendRequest({
      method: "POST",
      timeout,
      url,
      headers,
      data,
    });
  };

  // Function to send a PUT request
  const updateData = async (data: any): Promise<T | null> => {
    return await sendRequest({
      method: "PUT",
      timeout,
      url,
      headers,
      data,
    });
  };

  // Function to send a DELETE request
  const deleteData = async (): Promise<boolean> => {
    const response = await sendRequest({
      method: "DELETE",
      timeout,
      url,
      headers
    });
    return !!response;
  };

  return {
    loading,
    error,
    get: fetchData,
    post: createData,
    push: updateData,
    delete: deleteData,
  };
}

export default useAxios;
