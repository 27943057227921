import Footer from "./Footer";
import { ThemeProvider } from "styled-components";
import { theme } from "../styles/theme";
import GlobalStyles from "../styles/globalStyles";
import Navbar from "./Navbar";
import Box from "../styles/Box";
import Hat from "./Hat";
import { Page } from "../styles/elements";

const Layout = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <>
      <Hat />
      <ThemeProvider theme={theme}>
      <GlobalStyles />
        <Page maxWidth={"100vw"} maxHeight={"100vh"}>
          <Navbar />
          {children}
          <Footer />
        </Page>
      </ThemeProvider>
    </>
  );
};

export default Layout;
