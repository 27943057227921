import styled from "styled-components"
import { bg, text } from "./styles";
import Box from "./Box";
import { PageHeading, SectionHeading, SubHeading, CaptionHeading, Caption, Body, NavLink, AnchorLink } from "./Text";
import { theme } from "./theme";

interface IProps {
  image: string;
  dark: boolean;
  grid: boolean;
  centerXY: boolean;
  centerX: boolean;
  left: boolean;
  gap: string;
  noPad: boolean;
  transparent: boolean;
  indent: boolean;
  link: boolean;
  noShadow: boolean;
}

export const Page = styled(Box)`
  background-color: ${props => props.theme.colors.dark};
`

export const Hero = styled(Box)<Partial<IProps>>`
  ${props => bg.image(props.image as string)}
  ${props => !props.image && `background-color: ${props.dark ? props.theme.colors.black : props.theme.colors.white};`}
`

const Section = styled(Box)<Partial<IProps>>`
  ${props => props.image && bg.image(props.image as string)}
  ${props => !props.image && !props.transparent && `background-color: ${props.dark ? props.theme.colors.black : props.theme.colors.white};`}
`

Section.defaultProps = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  maxWidth: theme.width.xl7,
  minHeight: '40vh',
  mx: 'auto',
}

export const SectionB = styled(Box).attrs<Partial<IProps>>(props => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: props.theme.width.xl5,
  mx: 'auto',
  padding: 0,
}))<Partial<IProps>>`
  ${props => props.image && bg.image(props.image as string)}
  ${props => !props.image && !props.transparent && `background-color: ${props.dark ? props.theme.colors.black : props.theme.colors.white};`}
`

export const Inner = styled(Box).attrs(props => ({
  maxWidth: props.theme.width.xl7,
}))<Partial<IProps>>``

export const Container = styled(Box).attrs<IProps>(props => ({
  display: 'flex',
  justifyContent: props.centerX ? undefined : props.left ? 'left' : 'center',
  alignItems: !props.centerX ? 'center' : undefined,
  flexDirection: 'column',
}))<Partial<IProps>>`
  ${props => props.image && bg.image(props.image as string)}
`

export const Background = styled(Box).attrs<IProps>(props => ({
  display: 'flex',
  justifyContent: !props.centerX ? 'center' : undefined,
  alignItems: !props.centerX ? 'center' : undefined,
  flexDirection: 'column',
}))<Partial<IProps>>`
  ${props => props.image && bg.image(props.image as string)}
  ${props => !props.image && `background-color: ${props.dark ? props.theme.colors.black : props.theme.colors.white};`}
`

export const Overlay = styled(Box).attrs<IProps>(props => ({
  display: 'flex',
  justifyContent: !props.centerX ? 'center' : undefined,
  alignItems: !props.centerX ? 'center' : undefined,
  flexDirection: 'column',
}))<Partial<IProps>>`
  ${props => bg.overlay(props.dark as boolean)}
`

export const Heading1 = styled(PageHeading)<Partial<IProps>>`
  ${props => text.color(props.dark as boolean)}
  ${props => !props.noShadow && text.shadow(props.dark as boolean)}
`

export const Heading2 = styled(SectionHeading)<Partial<IProps>>`
  ${props => text.color(props.dark as boolean)}
  ${props => !props.noShadow && text.shadow(props.dark as boolean)}
`

export const Heading3 = styled(SubHeading)<Partial<IProps>>`
  ${props => text.color(props.dark as boolean)}
  ${props => !props.noShadow && text.shadow(props.dark as boolean)}
`

export const Text = styled(Body)<Partial<IProps>>`
  ${props => text.color(props.dark as boolean)}
  ${props => !props.noShadow && text.shadow(props.dark as boolean)}
  ${props => props.indent && text.indent}
  ${props => props.link && text.link(props.dark as boolean)}
`

export const SmallHeading = styled(CaptionHeading)<Partial<IProps>>`
  ${props => text.color(props.dark as boolean)}
`

export const SmallText = styled(Caption)<Partial<IProps>>`
  ${props => text.color(props.dark as boolean)}
  ${props => props.link && text.link(props.dark as boolean)}
`

export const Link = styled(NavLink)<Partial<IProps>>`
  ${props => text.link(props.dark as boolean)}
  text-decoration: underline;
`

export const Anchor = styled(AnchorLink)<Partial<IProps>>`
  ${props => text.link(props.dark as boolean)}
  text-decoration: underline;
`

export const List = styled.ul<Partial<IProps>>`
  list-style-position: inside;
  ${props => text.color(props.dark as boolean)}
`;

export { Section };