export const api = {
  base_url: "https://mp6hvovha1.execute-api.us-east-1.amazonaws.com/v1",
  key: process.env.REACT_APP_API_KEY,
  endpoint: "/form/message",
};

export const portfolio = [
  {
    title: "Mixing & Mastering Examples",
    type: "audio",
    sources: [
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Tytillidie+X+Xollin+-+Bankroll+-+Before.wav",
        description: "Bankroll from Tytillidie - original stems.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Tytillidie+X+Xollin+-+Bankroll+-+After.wav",
        description: "Bankroll from Tytillidie - mastered.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Golden+UFO+-+Feeling+Like+A+Shadow+-+RAW.wav",
        description:
          "Feeling Like A Shadow from The Golden UFO - original stems.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Golden+UFO+-+Feeling+Like+A+Shadow+-+MASTER.wav",
        description: "Feeling Like A Shadow from The Golden UFO - mastered.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Blues+Bastards+-+Sugar+-+Before.wav",
        description: "Sugar from Blues Bastards - original stems.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Blues+Bastards+-+Sugar+-+After.wav",
        description: "Sugar from Blues Bastards - mastered.",
      },
      //{
      //  path: "https://alkenox-media.s3.amazonaws.com/audio/Blues+Bastards+-+Lights+Out+-+Before.wav",
      //  description: "Lights Out from Blues Bastards - original stems.",
      //},
      //{
      //  path: "https://alkenox-media.s3.amazonaws.com/audio/Blues+Bastards+-+Lights+Out+-+After.wav",
      //  description: "Lights Out from Blues Bastards - mastered.",
      //},
    ],
  },
  {
    title: "Original Music & Remixes",
    type: "audio",
    sources: [
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Triangulation.wav",
        description:
          "Triangulation from The Alkenox Library. An odd time instrumental that fuses rock, electronic, and orchestra.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Latency.mp3",
        description:
          "Latency from the Alkenox™️ Library which is a fusion of electronic and rock elements.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/The+Hand+That+Feeds+(Robot+Monk's+Remix).wav",
        description:
          "Our remix of The Hand That Feeds from NIN, originally featured on Blend.io's platform.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Golden+UFO+-+Feeling+Like+A+Shadow+-+REMIX.wav",
        description:
          "Our remix of Feeling Like A Shadow from The Golden UFO which won a remix contest held by the group on Blend.io.",
      },
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Lowryde.mp3",
        description:
          "Lo-Ryde from the Alkenox™️ Library which is another fusion of rock and electronic elements.",
      },
    ],
  },
  {
    title: "Aeon Flux Example",
    type: "video",
    sources: [
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/Aeon+Flux+Example.wav",
        description:
          "The video was initially silent giving us the opportunity to create the entire soundscape, the foley elements and we produced the music score for it.",
      },
    ],
  },
  {
    title: "Film Score",
    type: "audio",
    sources: [
      {
        path: "https://alkenox-media.s3.amazonaws.com/audio/film_score_reel.mp3",
        description: "A film score reel consisting of a mix of film genres.",
      },
    ],
  },
];
