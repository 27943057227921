import React from 'react';
import Box, { Embed } from '../styles/Box';

interface IProps {
  url: string;
  title: string;
}

const VimeoPlayer: React.FC<IProps> = ({ url, title }) => {
  return (
    <Box
        position={'relative'}
        paddingBottom={"56.25%"}
        height={0}
        overflow={'hidden'}
        marginY={'1em'}
    >
      <Embed
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'100%'}
        border={0}
        src={url}
        title={title}
        frameBorder="0"
        allowFullScreen
      />
    </Box>
  );
};

export default VimeoPlayer;
