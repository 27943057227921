import { createGlobalStyle } from 'styled-components';
import { text } from './styles';
import { theme } from './theme';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  /* Reset default browser styles */
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
    display: none;
  }

  /* Modern minimal styles */
  body {
    font-family: ${theme.font.family.body};
    line-height: 1.5;
    font-size: 1rem;
    color: ${theme.colors.dark};
    background-color: ${theme.colors.dark};
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
    text-decoration: underline;
    font-family: ${theme.font.family.title};
  }

  h2 {
    font-size: 2rem;
    font-weight: bold;
    text-decoration: underline;
    font-family: ${theme.font.family.title};
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: ${theme.font.family.subtitle};
  }

  h4 {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: ${theme.font.family.subtitle};
  }

  h5 {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: ${theme.font.family.black};
  }

  h6 {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: ${theme.font.family.black};
  }

  caption {
    font-size: 0.875rem;
    font-family: ${theme.font.family.black};
  }

  a {
    ${props => text.link(false)}
  }

  /* Add your custom styles here */
`;

export default GlobalStyles;