import { Container, Hero, SmallText } from "../styles/elements";

export default function Footer() {
  return (
    <Hero dark minHeight={'3rem'}>
      <Container>
        <SmallText dark mt={'1rem'} textAlign={'center'}>Copyright &copy; 2023 LeeVan Enterprises, LLC. All rights reserved.</SmallText>
      </Container>
    </Hero>
  );
}
