import React from "react";
import Services from "../components/Services";
import ContactForm from "../components/MessageUs";
import {
  Container,
  Overlay,
  Page,
  Section,
  Text,
  Heading2,
  Inner,
  SmallHeading,
} from "../styles/elements";
import { theme } from "../styles/theme";
import Box, { Image } from "../styles/Box";
import Slider, { Slide } from "../components/Slider";
import styled from "styled-components";

const OverlayB = styled(Box)`
  background-color: #00000090;
`;

const OverlayC = styled(Box)`
  background-color: #000000bb;
`;

OverlayB.defaultProps = {
  display: "flex",
  flexDirection: "column",
};

OverlayC.defaultProps = {
  display: "flex",
  flexDirection: "column",
};

export default function Index() {
  const ourProcess = [
    <Slide
      hero
      image={"https://alkenox-media.s3.amazonaws.com/images/waveform_04.jpeg"}
      heading={"From Raw Materials To A Polished Masterpiece"}
    />,
    <Slide
      hero
      image={"https://alkenox-media.s3.amazonaws.com/images/vinylFilm_03.jpeg"}
      heading={"Updates"}
      description={
        "Between each phase you will be sent the updated version for your review. Because the mix requires headroom for processing, you will receive the raw mix and a version of the mix that is brought to a loudness which will allow you to preview the material on a device or playback system of your choice without the need for a pre-amp."
      }
    />,
    <Slide
      hero
      image={"https://alkenox-media.s3.amazonaws.com/images/shaman_03.jpeg"}
      heading={"Source Material Preparation"}
      description={
        "This is where we clean up, edit, and repair your source material. Comping dialog, instruments, or vocals as needed. And we bring the sources to unity gain with some dynamic treatment if necessary."
      }
    />,
    <Slide
      hero
      image={"https://alkenox-media.s3.amazonaws.com/images/alchemist_03.jpeg"}
      heading={"The Initial Mix"}
      description={
        "This is where we balance all of your sound sources spectrally and dynamically to ensure the focal points of each element can be heard. Our main approach here will be subtractive mixing until it sounds clean and balanced as a whole."
      }
    />,
    <Slide
      hero
      image={"https://alkenox-media.s3.amazonaws.com/images/alchemist_05.jpeg"}
      heading={"The Creative Mix"}
      description={
        "This is where we can take a creative approach finding the most effective ways to embellish your source or the work as a whole. For video creators, this is the point where sound design comes in. If you need music we will help you find and secure licenses through the necessary owners on your behalf, find matching alternatives, or produce music cues based on references you provide."
      }
    />,
    <Slide
      hero
      image={"https://alkenox-media.s3.amazonaws.com/images/shaman_04.jpeg"}
      heading={"The Final Mix & Master"}
      description={
        "We re-balance the sources and glue them all together. After which we will optimize the loudness for distribution and finish shaping the overall tone. We will export audio files using a similar algorithm as your chosen streaming platform in order for you to have an accurate preview of how your content will translate."
      }
    />,
    <Slide
      hero
      image={"https://alkenox-media.s3.amazonaws.com/images/vinylFilm_04.jpeg"}
      heading={"The Delivery"}
      description={
        "All deliveries will include the project file, the stems (if applicable), the mastered audio or the final video with the audio embedded, along with a distribution ready version, and stream-ready version(s) - all in ProRes 422, WAV or AIFF format. We will also include a lossless copy in FLAC, ALAC, or ProRes 4444 format along with a lossy copy in MP4, MP3, or AAC format. All files will be encoded with the metadata you provide."
      }
    />,
  ];
  return (
    <>
      <Section
        dark
        marginTop={'6vh'}
        //backgroundImage={`linear-gradient(260deg, ${theme.colors.dark} 0%, ${theme.colors.silver} 50%, ${theme.colors.dark} 100%);`}
        image={theme.images.music_studio}
      >
        <OverlayC width={1}>
          <Box
            margin={["2.5rem", "2.5rem", "auto 2.5rem", "auto"]}
            maxWidth={theme.width.xl3}
          >
            <Text dark textAlign={"left"}>
              Welcome to Alkenox&trade; where the alchemy of technology and
              artistry transmutes your raw creative materials into polished
              masterpieces. Our dedication to turning ideas into captivating
              content knows no bounds, and our passion for enhancing your
              creative works resonates in every project we undertake. With a
              blend of innovation, precision, and an open-minded approach, we
              empower creators like you to achieve your artistic visions with
              unparalleled quality.
            </Text>
          </Box>
        </OverlayC>
      </Section>
      {/*
      <Section  id={'reels'} dark image={"https://alkenox-media.s3.amazonaws.com/images/waveform_07.jpeg"}>
      <Overlay  width={[1,1,1,2/3]} padding={["2.5rem","2.5rem","2.5rem", "5rem"]} borderRadius={[0,0, 0, "1rem"]} marginX={'auto'} marginY={[0,0,0, '5rem']}>
      <Heading2>Our Work</Heading2>
      <VersionPlayer media={portfolio} />
      </Overlay>
      </Section>
      */}
      <Section
        id={"services"}
        dark
        backgroundImage={`linear-gradient(180deg, ${theme.colors.dark} 0%, ${theme.colors.vibrant} 50%, ${theme.colors.dark} 100%);`}
      >
        <OverlayB>
          <Inner padding={["2.5rem", "2.5rem", "5rem"]}>
            <Container paddingBottom={"1rem"}>
              <Text dark textAlign={"left"}>
                At Alkenox&trade; we thrive on the fusion of creativity and
                technology, where each sound we craft becomes a testament to our
                passion for sonic excellence. Our journey began with a vision to
                empower creators across diverse fields, transforming their raw
                concepts into immersive and captivating experiences. Our unique
                approach embraces scientific precision, artistic finesse, and an
                open-minded spirit, delivering results that not only meet but
                exceed expectations.
              </Text>
            </Container>

            <Box
              width={1}
              display={"flex"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
            >
              <Box
                display={["flex", "inline-flex"]}
                justifyContent={"center"}
                alignContent={"start"}
                width={[1, 1, 1, 0.9 / 3]}
                flexWrap={"wrap"}
              >
                <Image
                  src={
                    "https://alkenox-media.s3.amazonaws.com/images/precision_and_passion.svg"
                  }
                  alt={"Precision Meets Passion"}
                  width={["8rem"]}
                  margin={["1rem"]}
                  marginX={["auto", "2.5rem"]}
                />
                <Box>
                  <SmallHeading dark textAlign={"center"}>
                    Precision Meets Passion
                  </SmallHeading>
                  <Text dark textAlign={"left"}>
                    Our approach is rooted in precision and guided by our
                    unyielding passion for sound and vision. With a deep
                    understanding of both the technical and artistic aspects of
                    production, we delicately weave together elements that
                    resonate with authenticity and creativity.
                  </Text>
                </Box>
              </Box>
              <Box
                display={["flex", "inline-flex"]}
                justifyContent={"center"}
                alignContent={"start"}
                width={[1, 1, 1, 0.9 / 3]}
                flexWrap={"wrap"}
              >
                <Image
                  src={
                    "https://alkenox-media.s3.amazonaws.com/images/scientific_artistry.svg"
                  }
                  alt={"Scientific Artistry"}
                  width={["8rem"]}
                  margin={["1rem"]}
                  marginX={["auto", "2.5rem"]}
                />
                <Box>
                  <SmallHeading dark textAlign={"center"}>
                    Scientific Artistry
                  </SmallHeading>
                  <Text dark textAlign={"left"}>
                    We merge the scientific with the artistic, turning raw
                    materials into auditory gold. Our team's expertise spans the
                    spectrum, from music production to post production. We
                    embrace each project with an open mind, ensuring every
                    element serves your vision's purpose.
                  </Text>
                </Box>
              </Box>
              <Box
                display={["flex", "inline-flex"]}
                justifyContent={"center"}
                alignContent={"start"}
                width={[1, 1, 1, 0.9 / 3]}
                flexWrap={"wrap"}
              >
                <Image
                  src={
                    "https://alkenox-media.s3.amazonaws.com/images/client_catalyst.svg"
                  }
                  alt={"Technical Assistance"}
                  width={["8rem"]}
                  margin={["1rem"]}
                  marginX={["auto", "2.5rem"]}
                />
                <Box>
                  <SmallHeading dark textAlign={"center"}>
                    Technical Assistance
                  </SmallHeading>
                  <Text dark textAlign={"left"}>
                    Your ideas are the foundation, and we are the catalysts that
                    turn them into extraordinary experiences. Whether you're a
                    musician, filmmaker, podcaster, or educator, we tailor our
                    services to your unique needs, ensuring every element is a
                    reflection of your vision.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Inner>
        </OverlayB>
      </Section>
      <Section>
        <Services />
      </Section>
      <Section
        dark
        backgroundImage={`linear-gradient(260deg, ${theme.colors.dark} 0%, ${theme.colors.red} 30%, ${theme.colors.dark} 90%);`}
      >
        <Inner padding={["1rem", "1rem", "2.5rem", "5rem 10rem"]}>
          <Container dark>
            <Text dark indent marginBottom={"1rem"} textAlign={"left"}>
              Our prices can be affected by a number of things including but not
              limited to the amount of source material, the duration of the
              source material, the target duration of the final deliverable, the
              level of complexity, and the project timeline. We offer dynamic
              discounts for batch projects such as music albums, podcast series,
              and video series. When you contact us we will work with you to
              develop a service package that balances your budget and your
              project's needs.
            </Text>
            <Text dark indent marginBottom={"1rem"} textAlign={"left"}>
              After your project is finished, we will provide a digital download
              of your deliverables. Should you require a hard copy, a Solid
              State Drive will be shipped to you at an extra cost. You may opt
              for us to archive the project for an annual fee. If you choose not
              to, yours will be the only copy and you will be solely responsible
              for it after delivery. In either case, if you find yourself in
              need of adjustments or alternative versions, you can send us your
              copy unless we have it archived and your adjustments will be
              charged at a discount depending on what's required.
            </Text>
          </Container>
        </Inner>
      </Section>
      <Section id={"about"} image={theme.images.collab}>
        <Overlay
          width={[1, 1, 1, 2 / 3]}
          padding={["2.5rem", "2.5rem", "2.5rem", "5rem"]}
          borderRadius={[0, 0, 0, "1rem"]}
          marginX={"auto"}
          marginY={[0, 0, 0, "5rem"]}
        >
          <Text indent textAlign={"left"}>
            At the core we are a team of dedicated audio visual enthusiasts who
            bring their expertise, experience, and a deep love for the art of
            sound and vision. Our family-owned and -run business nurtures a
            culture of collaboration, innovation, and meticulous attention to
            detail. With a background in audio engineering, music production,
            film scoring, and post production, we've curated a range of services
            that cater to a diverse array of creative individuals and entities,
            from musicians, podcasters, filmmakers, game creators, educators,
            and small businesses.
          </Text>
          <br />
          <Text indent textAlign={"left"}>
            We embark on a transformative journey, turning the ordinary into the
            extraordinary, infusing creative projects with a touch of alchemical
            magic. Our brand is a beacon for those who seek the perfect blend of
            scientific precision, artistic brilliance, and cutting-edge
            technology. We strive to harmonize the raw and the refined,
            breathing life into ideas and crafting immersive experiences that
            captivate and resonate.
          </Text>
          <br />
          <Text indent textAlign={"left"}>
            Our mission is to empower creators across the spectrum, from
            independent artists and filmmakers to content creators and
            educators. Through a fusion of meticulous artistry and technological
            prowess, we elevate content into captivating creations.
            Alkenox&trade; is a gateway to unlimited creative possibilities,
            embodying a culture of innovation, open-mindedness, and
            collaboration.
          </Text>
        </Overlay>
      </Section>
      <Section
        id={"contact"}
        dark
        flexDirection={"column"}
        //backgroundImage={`linear-gradient(260deg, ${theme.colors.dark} 10%, ${theme.colors.gold} 50%, ${theme.colors.dark} 90%);`}
        image={theme.images.box2}
      >
        <OverlayC>
          <Container
            marginX={"auto"}
            maxWidth={theme.width.lg}
            padding={"2.5rem"}
            marginBottom={"2rem"}
          >
            <Heading2 dark textAlign={"center"}>
              Send Us A Message
            </Heading2>
            <Text dark marginBottom={"2rem"} textAlign={"left"}>
              We are here to assist you where ever we can. If you don't know
              where to start feel free to ask questions and receive a free
              consultation while we create a service package that best suites
              your needs.
            </Text>
            <ContactForm />
          </Container>
        </OverlayC>
      </Section>
    </>
  );
}
