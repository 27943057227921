import React from "react";

function AlkenoxLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
      clipRule="evenodd"
      viewBox="0 0 4001 901"
    >
      <path fill="none" d="M0.006 0.873H4000.006V900.873H0.006z"></path>
      <g>
        <g filter="url(#_Effect1)">
          <g>
            <path
              fillRule="nonzero"
              d="M3611.95 240.791v69.612h-30.103v-69.612h-30.808v-23.988h91.484v23.988h-30.573zm120.174 69.612v-29.162c0-6.585.353-13.444 1.059-20.578.705-7.133 1.215-11.719 1.528-13.757h-.94l-17.403 63.497h-23.518l-17.403-63.262h-.941c.314 2.038.863 6.585 1.647 13.64.784 7.055 1.176 13.875 1.176 20.46v29.162h-27.516v-93.6h42.332l13.875 54.091h1.176l13.64-54.091h40.921v93.6h-29.633z"
            ></path>
            <path d="M3170.12 424.49l-146.857-146.857 99.903-99.903 146.857 146.857 146.856-146.857 99.903 99.903-146.857 146.857 146.857 146.857-99.903 99.903-146.856-146.857-146.857 146.857-99.903-99.903L3170.12 424.49z"></path>
            <path d="M2571.13 632.096H2949.7980000000002V671.25H2571.13z"></path>
            <path d="M2924.26 485.943c-55.237 95.674-177.759 128.504-273.433 73.266-95.674-55.237-128.504-177.759-73.266-273.433-26.983 80.096 12.085 180.327 98.287 230.096 86.201 49.768 192.538 33.487 248.412-29.929zm-346.699-200.167c55.237-95.674 177.759-128.504 273.433-73.266 95.674 55.237 128.504 177.759 73.266 273.433 26.982-80.096-12.085-180.327-98.287-230.096-86.202-49.768-192.538-33.486-248.412 29.929zm173.349 25.02c41.429 0 75.064 33.635 75.064 75.064 0 41.428-33.635 75.063-75.064 75.063-41.428 0-75.063-33.635-75.063-75.063 0-41.429 33.635-75.064 75.063-75.064z"></path>
            <path
              fillRule="nonzero"
              d="M2344.11 216.803c41.479 0 72.588 11.497 93.327 34.491 20.739 22.993 31.109 56.131 31.109 99.413v250.901c0 43.282-9.806 74.503-29.418 93.665-19.612 19.161-51.059 28.742-94.342 28.742-11.722 0-26.149-1.127-43.282-3.382-17.132-2.254-30.658-5.184-40.577-8.791v-80.478h35.167c25.248 0 37.872-14.427 37.872-43.282V366.938c0-15.329-3.945-27.615-11.835-36.858-7.89-9.242-19.049-13.863-33.476-13.863-16.682 0-30.207 5.41-40.577 16.23-10.37 10.821-15.554 24.121-15.554 39.901v209.648h-134.581V224.919h110.234l8.792 54.102c13.977-18.936 32.349-34.039 55.117-45.311 22.768-11.271 46.776-16.907 72.024-16.907zM1797.38 287.955v1.923l85.094 123.074-101.44 145.669v2.404h200.476v71.152h-314.896v-40.864l114.901-166.342-108.651-154.804v-53.364h296.146v71.152h-171.63zM1436.28 316.451h138.728l-116.604 127.367 119.593 188.359h-135.14l-66.374-108.232-41.26 40.064v68.168h-118.995V198.054h118.995V437.24l101.057-120.789zM1143.91 565.205v66.972H770.181V258.448h72.952v306.757h300.777zM387.769 216.803h157.576l141.879 373.112v42.262H246.493v-42.262l141.276-373.112zm144.898 323.002l-67.016-223.988h-2.415l-70.637 223.988h140.068z"
            ></path>
          </g>
        </g>
        <g stroke="#001f3f" strokeWidth="4.09">
          <path
            fill="url(#_Linear2)"
            fillRule="nonzero"
            d="M3611.95 240.791v69.612h-30.103v-69.612h-30.808v-23.988h91.484v23.988h-30.573zm120.174 69.612v-29.162c0-6.585.353-13.444 1.059-20.578.705-7.133 1.215-11.719 1.528-13.757h-.94l-17.403 63.497h-23.518l-17.403-63.262h-.941c.314 2.038.863 6.585 1.647 13.64.784 7.055 1.176 13.875 1.176 20.46v29.162h-27.516v-93.6h42.332l13.875 54.091h1.176l13.64-54.091h40.921v93.6h-29.633z"
          ></path>
          <path
            fill="url(#_Linear3)"
            d="M3170.12 424.49l-146.857-146.857 99.903-99.903 146.857 146.857 146.856-146.857 99.903 99.903-146.857 146.857 146.857 146.857-99.903 99.903-146.856-146.857-146.857 146.857-99.903-99.903L3170.12 424.49z"
          ></path>
          <path
            fill="#c3c1be"
            d="M2571.13 632.096H2949.7980000000002V671.25H2571.13z"
          ></path>
          <path
            fill="url(#_Linear4)"
            strokeLinecap="butt"
            strokeMiterlimit="2"
            d="M2924.26 485.943c-55.237 95.674-177.759 128.504-273.433 73.266-95.674-55.237-128.504-177.759-73.266-273.433-26.983 80.096 12.085 180.327 98.287 230.096 86.201 49.768 192.538 33.487 248.412-29.929zm-346.699-200.167c55.237-95.674 177.759-128.504 273.433-73.266 95.674 55.237 128.504 177.759 73.266 273.433 26.982-80.096-12.085-180.327-98.287-230.096-86.202-49.768-192.538-33.486-248.412 29.929zm173.349 25.02c41.429 0 75.064 33.635 75.064 75.064 0 41.428-33.635 75.063-75.064 75.063-41.428 0-75.063-33.635-75.063-75.063 0-41.429 33.635-75.064 75.063-75.064z"
          ></path>
          <path
            fill="url(#_Linear5)"
            fillRule="nonzero"
            d="M2344.11 216.803c41.479 0 72.588 11.497 93.327 34.491 20.739 22.993 31.109 56.131 31.109 99.413v250.901c0 43.282-9.806 74.503-29.418 93.665-19.612 19.161-51.059 28.742-94.342 28.742-11.722 0-26.149-1.127-43.282-3.382-17.132-2.254-30.658-5.184-40.577-8.791v-80.478h35.167c25.248 0 37.872-14.427 37.872-43.282V366.938c0-15.329-3.945-27.615-11.835-36.858-7.89-9.242-19.049-13.863-33.476-13.863-16.682 0-30.207 5.41-40.577 16.23-10.37 10.821-15.554 24.121-15.554 39.901v209.648h-134.581V224.919h110.234l8.792 54.102c13.977-18.936 32.349-34.039 55.117-45.311 22.768-11.271 46.776-16.907 72.024-16.907z"
          ></path>
          <path
            fill="url(#_Linear6)"
            fillRule="nonzero"
            d="M1797.38 287.955v1.923l85.094 123.074-101.44 145.669v2.404h200.476v71.152h-314.896v-40.864l114.901-166.342-108.651-154.804v-53.364h296.146v71.152h-171.63z"
          ></path>
          <path
            fill="url(#_Linear7)"
            fillRule="nonzero"
            d="M1436.28 316.451h138.728l-116.604 127.367 119.593 188.359h-135.14l-66.374-108.232-41.26 40.064v68.168h-118.995V198.054h118.995V437.24l101.057-120.789z"
          ></path>
          <path
            fill="url(#_Linear8)"
            fillRule="nonzero"
            d="M1143.91 565.205v66.972H770.181V258.448h72.952v306.757h300.777z"
          ></path>
          <path
            fill="url(#_Linear9)"
            fillRule="nonzero"
            d="M387.769 216.803h157.576l141.879 373.112v42.262H246.493v-42.262l141.276-373.112zm144.898 323.002l-67.016-223.988h-2.415l-70.637 223.988h140.068z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="_Effect1"
          width="3835.89"
          height="1027.4"
          x="126.411"
          y="-89.209"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur
            in="SourceGraphic"
            stdDeviation="20.014"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="_Linear2"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(0 -82.7817 9.89273 0 3663.4 295.822)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="silver"></stop>
          <stop offset="0.53" stopColor="#cec595"></stop>
          <stop offset="1" stopColor="gold"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear3"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(0 -454.446 100.837 0 3424.98 671.25)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="silver"></stop>
          <stop offset="0.61" stopColor="#cbc4a0"></stop>
          <stop offset="0.82" stopColor="#e4cd53"></stop>
          <stop offset="1" stopColor="gold"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear4"
          x1="0"
          x2="1"
          y1="0"
          y2="-0.006"
          gradientTransform="matrix(166.702 -369.136 371.974 165.43 2667.58 570.434)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#a9a9a9"></stop>
          <stop offset="0.48" stopColor="#c0b57b"></stop>
          <stop offset="1" stopColor="gold"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear5"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(-.45958 -599.049 83.1992 -.06383 2295.61 724.015)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="silver"></stop>
          <stop offset="0.61" stopColor="#cbc4a0"></stop>
          <stop offset="0.82" stopColor="#e4cd53"></stop>
          <stop offset="1" stopColor="gold"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear6"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(0 -169.226 20.2232 0 1834.06 396.606)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="silver"></stop>
          <stop offset="0.53" stopColor="#cec595"></stop>
          <stop offset="1" stopColor="gold"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear7"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(0 -210.484 25.1536 0 1392.21 421.693)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="silver"></stop>
          <stop offset="0.53" stopColor="#cec595"></stop>
          <stop offset="1" stopColor="gold"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear8"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(0 -210.484 25.1536 0 969.48 421.693)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="silver"></stop>
          <stop offset="0.53" stopColor="#cec595"></stop>
          <stop offset="1" stopColor="gold"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear9"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(0 -212.517 25.3966 0 478.21 419.66)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="silver"></stop>
          <stop offset="0.53" stopColor="#cec595"></stop>
          <stop offset="1" stopColor="gold"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AlkenoxLogo;