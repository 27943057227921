import React, { useState } from "react";
import styled from "styled-components";
import Box from "../styles/Box"
import AlkenoxLogo from "../styles/Alkenox";
import { Hamburger } from "../styles/icons";
import { theme } from "../styles/theme";
import { Anchor } from "../styles/elements";
 
const Bar = styled(Box)`
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100vw;
  min-height: 6vh;
  background-image: linear-gradient(
    260deg,
    ${props => props.theme.colors.vibrant} 0%,
    ${props => props.theme.colors.dark} 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
  @media (min-width: ${props => props.theme.screens.md}) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    align-items: center;
  }
`;

const MainNav = styled.ul<{ display: string }>`
  list-style-type: none;
  display: ${(props) => props.display};
  flex-direction: column;
  margin-left: auto;
  margin-top: 6vh;
  margin-bottom: auto;
  @media (min-width: ${props => props.theme.screens.md}) {
    display: flex;
    margin-right: 3vw;
    margin-top: 1vh;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const NavLi = styled.li`
  text-align: center;
  margin: .25rem auto;
`;

const NavLink = styled.a`
  font-size: 1.25rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  color: ${props => props.theme.colors.white};
  text-shadow: 0 0 .185rem ${props => props.theme.colors.dark};
  @media (min-width: ${props => props.theme.screens.md}) {
    margin: 0px .35rem;
    font-size: 1.125rem;
  }
`;

const NavBarToggle = styled.span`
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  font-size: 1.5rem;
  @media (min-width: ${props => props.theme.screens.md}) {
    top: 0px;
    right: 0px;
    margin: 0px .35rem;
    font-size: 1.125rem;
  }
`;

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Bar>
      <NavBarToggle onClick={toggleMenu}>
        <Hamburger color={theme.colors.white} colorOnHover={theme.colors.gold} />
      </NavBarToggle>
      <Box
        width={["9rem","9rem","10rem","12rem"]}
        position={"absolute"}
        left={0}
        top={['1.5vh','1.5vh','1.5vh','1vh']}
      >
        <Anchor href={'https://www.alkenox.com'}>
          <AlkenoxLogo />
        </Anchor>
      </Box>
      <MainNav display={menuOpen ? "flex" : "none"}>
        <NavLi>
          <NavLink href={'/'}>Home</NavLink>
        </NavLi>
        <NavLi>
          <NavLink href={'#services'}>Services</NavLink>
        </NavLi>
        <NavLi>
          <NavLink href={'#about'}>About</NavLink>
        </NavLi>
        <NavLi>
          <NavLink href={'#contact'}>Contact</NavLink>
        </NavLi>
        {/**
          <NavLi>
            <NavLink href={'/blog'}>blog</NavLink>
          </NavLi>
         */}
          <NavLi><NavLink href="https://users.alkenox.com/">Members</NavLink></NavLi>
      </MainNav>
    </Bar>
  );
};

export default Navbar;
