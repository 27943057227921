import React from "react";
import styled from "styled-components";

export interface IIconStyles {
  left?: boolean;
  right?: boolean;
}

const Burger = styled.div`
  /* Define your SVG styles here */
  svg {
    fill: ${(props) => props.theme.colors.white}; /* Change 'primary' to the desired color */
    width: 1.5rem; /* Set width and height to match your SVG dimensions */
    height: 1.5rem;
  }

  @media (min-width: ${(props) => props.theme.screens.md}) {
    display: none;
  }
`;

export const Hamburger: React.FC<{
  color: string;
  colorOnHover?: string;
}> = ({ color = "inherit", colorOnHover }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;
return (
  <Burger>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="32"
      height="32"
         onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill} d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z"/>
    </svg>
  </Burger>
);
}
export const CheckedCircle: React.FC<{
  color: string;
  colorOnHover?: string;
}> = ({ color = "inherit", colorOnHover }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fill-rule="evenodd"
        d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z"
        clip-rule="evenodd"
      />
    </svg>
  );
};
export const Visible: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const Invisible: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z"
      />
      <path
        fill={hoverFill}
        d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z"
      />
      <path
        fill={hoverFill}
        d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z"
      />
    </svg>
  );
};
export const ChevDown: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const ChevLeft: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const ChevRight: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const Home: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        d="M19.006 3.705a.75.75 0 1 0-.512-1.41L6 6.838V3a.75.75 0 0 0-.75-.75h-1.5A.75.75 0 0 0 3 3v4.93l-1.006.365a.75.75 0 0 0 .512 1.41l16.5-6Z"
      />
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M3.019 11.114 18 5.667v3.421l4.006 1.457a.75.75 0 1 1-.512 1.41l-.494-.18v8.475h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3v-9.129l.019-.007ZM18 20.25v-9.566l1.5.546v9.02H18Zm-9-6a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75H9Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const Play: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const Forward: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        d="M5.055 7.06C3.805 6.347 2.25 7.25 2.25 8.69v8.122c0 1.44 1.555 2.343 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.343 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256l-7.108-4.061C13.555 6.346 12 7.249 12 8.689v2.34L5.055 7.061Z"
      />
    </svg>
  );
};
export const Backward: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        d="M9.195 18.44c1.25.714 2.805-.189 2.805-1.629v-2.34l6.945 3.968c1.25.715 2.805-.188 2.805-1.628V8.69c0-1.44-1.555-2.343-2.805-1.628L12 11.029v-2.34c0-1.44-1.555-2.343-2.805-1.628l-7.108 4.061c-1.26.72-1.26 2.536 0 3.256l7.108 4.061Z"
      />
    </svg>
  );
};
export const Pause: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const Send: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"
      />
    </svg>
  );
};
export const Close: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Download: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const DownloadB: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M19.5 21a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3h-5.379a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H4.5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h15Zm-6.75-10.5a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V10.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Trash: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const EnvelopeOpen: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M19.5 22.5a3 3 0 0 0 3-3v-8.174l-6.879 4.022 3.485 1.876a.75.75 0 1 1-.712 1.321l-5.683-3.06a1.5 1.5 0 0 0-1.422 0l-5.683 3.06a.75.75 0 0 1-.712-1.32l3.485-1.877L1.5 11.326V19.5a3 3 0 0 0 3 3h15Z"
        clipRule="evenodd"
      />
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M1.5 9.589v-.745a3 3 0 0 1 1.578-2.642l7.5-4.038a3 3 0 0 1 2.844 0l7.5 4.038A3 3 0 0 1 22.5 8.844v.745l-8.426 4.926-.652-.351a3 3 0 0 0-2.844 0l-.652.351L1.5 9.589Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Envelope: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"
        clipRule="evenodd"
      />
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Folder: React.FC<{ color: string; colorOnHover?: string }> = ({
  color = "inherit",
  colorOnHover,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverFill = isHovered && colorOnHover ? colorOnHover : isHovered && !colorOnHover ? color : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={hoverFill}
        fillRule="evenodd"
        d="M19.906 9c.382 0 .749.057 1.094.162V9a3 3 0 0 0-3-3h-3.879a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H6a3 3 0 0 0-3 3v3.162A3.756 3.756 0 0 1 4.094 9h15.812ZM4.094 10.5a2.25 2.25 0 0 0-2.227 2.568l.857 6A2.25 2.25 0 0 0 4.951 21H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-2.227-2.568H4.094Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
