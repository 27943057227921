import React from "react";
import Box, { Embed } from "../styles/Box";

interface IProps {
  playlist: boolean;
  url: string;
}

const SoundCloudPlayer: React.FC<Partial<IProps>> = ({
  url,
  playlist,
}): JSX.Element => {
  return (
    <>
      {!playlist ? (
        <Embed
          marginY={"1em"}
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src={`https://w.soundcloud.com/player/?url=${url}&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true`}
        />
      ) : (
        <Box
          position={"relative"}
          paddingBottom={"56.25%"}
          height={'4rem'}
          overflow={"hidden"}
          marginY={"1em"}
        >
          <Embed
            position={"absolute"}
            top={0}
            left={0}
            width={"100%"}
            height={"100%"}
            border={0}
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src={`https://w.soundcloud.com/player/?url=${url}&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=false`}
          />
        </Box>
      )}
    </>
  );
};

export default SoundCloudPlayer;
