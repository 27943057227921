import React, { useState } from "react";
import styled from "styled-components";
import useAxios from "../hooks/useAxios";
import { Link } from "../styles/elements";

// Styled components
const FormContainer = styled.div`
  max-width: ${(props) => props.theme.width.lg};
  width: 100%;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Field = styled.label`
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const CheckboxLabel = styled.label`
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  margin-right: 0.75rem;
  border-radius: 0.5rem;
`;

const Label = styled.label`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
`;

const Input = styled.input`
  border-radius: 0.3rem;
  height: 1.5rem;
  padding: .5rem;
`;

const TextArea = styled.textarea`
  border-radius: 0.3rem;
  padding: .5rem;
`;

const ErrorMsg = styled.span`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${(props) => props.theme.colors.dark};
  color: ${(props) => props.theme.colors.white};
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  width: 10rem;
  margin: 0 auto;
  font-size: 1rem;
  &:hover {
    color: ${(props) => props.theme.colors.gold};
  }
`;

// Component
const ContactForm: React.FC = () => {
  const initData = {
    name: "",
    company: "",
    email: "",
    message: "",
    privacyPolicy: false,
  };

  const initErrors = {
    name: "",
    email: "",
    message: "",
    privacyPolicy: "",
  }

  const [formData, setFormData] = useState<{ [key: string]: string | boolean }>(initData);
  const [errors, setErrors] = useState<{ [key: string]: string }>(initErrors);

  const { post } = useAxios();

  const validateField = (fieldName: string) => {
    const { name, email, message, privacyPolicy } = formData;
    const errorsCopy = { ...errors };
    let isValid = true;
    // Validation logic for the specified field
    switch (fieldName) {
      case "name":
        // Validate Name
        if (!(name as string).trim()) {
          errorsCopy.name = "Name is required.";
          isValid = false;
        } else {
          errorsCopy.name = "";
        }
        break;
      case "email":
        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!(email as string).trim()) {
          errorsCopy.email = "Email is required.";
          isValid = false;
        } else if (!emailRegex.test(email as string)) {
          errorsCopy.email = "Invalid email format.";
          isValid = false;
        } else {
          errorsCopy.email = "";
        }
        break;
      case "message":
        // Validate Message
        if (!(message as string).trim()) {
          errorsCopy.message = "Message is required.";
          isValid = false;
        } else {
          errorsCopy.message = "";
        }
        break;
      //case "privacyPolicy":
      //  // Validate Message
      //  if (!privacyPolicy as boolean) {
      //    errorsCopy.privacyPolicy = "Privacy Policy is required.";
      //    isValid = false;
      //  } else {
      //    errorsCopy.privacyPolicy = "";
      //  }
      //  break;
      default:
        break;
    }
    setErrors(errorsCopy);
    return isValid;
  };

  const validateForm = () => {
    const { name, email, message, privacyPolicy } =
      formData;
    const errorsCopy = { ...errors };
    let isValid = true;

    // Validate Name
    if (!(name as string).trim()) {
      errorsCopy.name = "Name is required";
      isValid = false;
    } else {
      errorsCopy.name = "";
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!(email as string).trim()) {
      errorsCopy.email = "Email is required.";
      isValid = false;
    } else if (!emailRegex.test(email as string)) {
      errorsCopy.email = "Invalid email format.";
      isValid = false;
    } else {
      errorsCopy.email = "";
    }

    // Validate Message
    if (!(message as string).trim()) {
      errorsCopy.message = "Message is required.";
      isValid = false;
    } else {
      errorsCopy.message = "";
    }

    //// Validate Privacy Policy
    //if (!privacyPolicy as boolean) {
    //  errorsCopy.privacyPolicy = "Privacy Policy is required.";
    //  isValid = false;
    //} else {
    //  errorsCopy.privacyPolicy = "";
    //}

    setErrors(errorsCopy);
    return isValid;
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name } = e.target;
    validateField(name);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        type === "checkbox"
          ? !prevFormData[name as keyof typeof prevFormData]
          : value,
    }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      if (validateForm()) {
        console.log("submitting: ", formData); // Submit form data
        await post(JSON.stringify(formData));
      }
    } catch (error) {
      console.error("There was a problem sending your form.", error);
    } finally {
      if (validateForm()) {
        setFormData(initData);
        setErrors(initErrors);
      }
    }
  };

  return (
    <>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Field>
            <Label>
              Name:
              <Input
                type="text"
                name="name"
                value={formData.name as string}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Label>
            <ErrorMsg>{errors.name}</ErrorMsg>
          </Field>
          <Field>
            <Label>
              Company:
              <Input
                type="text"
                name="company"
                value={formData.company as string}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Label>
          </Field>
          <Field>
            <Label>
              Email:
              <Input
                type="email"
                name="email"
                value={formData.email as string}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Label>
            <ErrorMsg>{errors.email}</ErrorMsg>
          </Field>
          <Field>
            <Label>
              Message:
              <TextArea
                name="message"
                value={formData.message as string}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={8}
              />
            </Label>
            <ErrorMsg>{errors.message}</ErrorMsg>
          </Field>
          {/* Privacy policy checkbox */}
          {/**
           <Field>
            <CheckboxLabel>
              <CheckboxInput
                type="checkbox"
                name="privacyPolicy"
                checked={formData.privacyPolicy as boolean}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              I have read and accept the&nbsp;
              <Link dark to={'/privacy-policy'} target={'_blank'}>privacy policy</Link>
            </CheckboxLabel>
            <ErrorMsg>{errors.privacyPolicy}</ErrorMsg>
          </Field>
           */}
          <Button type="submit">Submit</Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default ContactForm;
